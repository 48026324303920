
Widgets.Tooltip = Class.create(Widgets.Base, {
    name:		'tooltip',
    skeleton:	true,

    css: 		[ '../../../core/lib/tippy/tippy.css', '../../../core/lib/tippy/themes/salonhub.css' ],

    defaults: {
        theme: 'salonhub',
        delay: 200
    },

    initWidget: function() {
        let options = {};

        let allowed = [ 'content', 'placement', 'theme', 'delay' ];
        
        for (let key in this.options) {
            if (allowed.indexOf(key) > -1) {
                options[key] = this.options[key];
            }
        }

        tippy(this.internal.container, options);
    }
});
