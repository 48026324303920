ChallengeWindow = Class.create();
ChallengeWindow.prototype = {
	initialize: function(container, options) {
		this.options = Object.assign({
			onSave: 	null,
			onCheck:	null,
			users: 		null
		}, options);

		this.window = new Window({
			width: 		200,
			height:		'auto',
			title: 		'Beheer mode',
			className:	'PinWindow',
			onClose:	this.destroy.bind(this),
			onShow: 	function() {
				this.passwordInput.focus();
			}.bind(this)
		});

		this.passwordInput = new Widgets.CodeField(this.window, {
			label: 		'Voer uw PIN code in om beheer mode te activeren:',
			onChange: 	this.onCheck.bind(this)
		});

		if (!this.options.onCheck) {
			this.saveButton = new Widgets.Button(this.window.navigation, {
				title:		'OK',
				onClick:	this.onSave.bind(this)
			});
		}

		this.onCheck();

		this.window.show();
	},

	destroy: function() {
		if (!this.options.onCheck) {
			Event.stopObserving(this.saveButton, 'click');
		}
	},

	onCheck: function() {
		if (this.options.onCheck) {
			var value = this.passwordInput.value;

			if (value.length == 4) {
				var correct = this.options.onCheck(value);

				if (correct) {
					this.onSave();
				} else {
					this.window.shake();
					this.passwordInput.value = '';
					this.passwordInput.focus();
				}
			}
		} else {
			var enabled = true;

			if (this.passwordInput.value == '') {
				enabled = false;
			}

			this.saveButton.enabled = enabled;
		}
	},

	onSave: function (e) {
		if (this.options.onSave) {
			this.options.onSave(this.passwordInput.value);
		}

		this.window.close();
	}
}
