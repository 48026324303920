CSS.insert(`
    .layout.sidebar { display: table; width: 100%; height: 100%; }
    .layout.sidebar > div { display: table-row; }
    .layout.sidebar > div > .left { display: table-cell; vertical-align: top; width: 170px; min-width: 170px; max-width: 170px; padding: 30px 20px 30px 30px; position: relative; }
    .layout.sidebar > div > .right { display: table-cell; vertical-align: top; padding: 30px; position: relative; }
    .layout.toolbar > .wrapper > .top .layout.sidebar > div > div { padding-top: 0 !important; }
    
    .layout.sidebar.separator { 
        background: 
            linear-gradient(to bottom, #fff 0px, rgba(255,255,255,0) 30px), 
            linear-gradient(to right, #fff 220px, #ddd 221px, #f6f6f6 221px, #fff 226px);  
    }
`);

Layout.Sidebar = Class.create();
Layout.Sidebar.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            separator:	true,
            visible:	true
        }, options || {});
        
        if (typeof parent.container != 'undefined') parent = parent.container;

        this.element = new Element('div', { 'class': 'layout sidebar' });
        parent.appendChild(this.element);

        if (this.options.separator) {
            this.element.classList.add('separator');
        }

        if (this.options.className) {
            this.element.classList.add(...this.options.className.split(' '));
        }
        
        var wrapper = new Element('div');
        this.element.appendChild(wrapper);
        
        this.left = new Element('div', { 'class': 'left' });
        wrapper.appendChild(this.left);

        this.right = new Element('div', { 'class': 'right' });
        wrapper.appendChild(this.right);
        
        if (!this.options.visible) {
            this.hide();
        }
    },
    
    hide: function() {
        this.element.hide();	
    },
    
    show: function() {
        this.element.show();	
    },
};
