Page.DoubleSidebar = Class.create(Page.Base, {
    name:		'doubleSidebar',

    css: `
        .page.doubleSidebar { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; color: #000; }
        
        .page.doubleSidebar > .panels { 
            display: flex; 
            flex-direction: row; 
            width: 100%; 
            min-height: 100%; 
            background: 
                linear-gradient(to bottom, #fff 0px, rgba(255,255,255,0) 30px), 
                linear-gradient(to right, #fff 220px, #ddd 221px, #f6f6f6 221px, #fff 227px, #fff 440px, #ddd 441px, #f6f6f6 440px, #fff 447px); 
        }
        
        
        .page.doubleSidebar > .panels > .panelPrimaryNavigation { width: 220px; min-width: 220px; padding: 30px 0 0 0; }
        .page.doubleSidebar > .panels > .panelPrimaryNavigation > * { margin-left: 30px; }
        .page.doubleSidebar > .panels > .panelSecondaryNavigation { width: 220px; min-width: 220px; padding: 30px 0 0 0; }
        .page.doubleSidebar > .panels > .panelSecondaryNavigation > * { margin-left: 30px; }
        .page.doubleSidebar > .panels > .panelContent { flex: 1; padding: 30px 30px 30px 31px; }


        /* Expanded viewport */

        body[data-viewport=expand] .page.doubleSidebar {
            position: static;
            width: 1000px;
            min-height: 100vh;
            overflow: visible;
        }

        body[data-viewport=expand] .page.doubleSidebar > .panels {
            min-height: 100vh;
        }


        /* Small viewport */

        @media (max-width: 799px) {

            .page.doubleSidebar > .panels {
                background: #fff;
                background: 
                    linear-gradient(to bottom, #fff 0px, rgba(255,255,255,0) 20px), 
                    linear-gradient(to right, #fff 30px, #ddd 31px, #f6f6f6 31px, #fff 36px, #fff 250px, #ddd 251px, #f6f6f6 251px, #fff 256px)
            }

            .page.doubleSidebar > .panels > .panelPrimaryNavigation {
                position: relative;
                background: #fff;
                padding-top: 30px;
                z-index: 2;
                margin-left: -190px;

                box-shadow: 0 0 10px #00000000;

                transition: transform 0.4s, box-shadow 0.4s;
                transition-delay: 0s, 0.2s;
            }

            .page.doubleSidebar.animating[data-sidebar=visible] > .panels > .panelPrimaryNavigation {
                transition-delay: 0s, 0s;
            }

            .page.doubleSidebar[data-sidebar=visible] > .panels > .panelPrimaryNavigation {
                box-shadow: 0 0 10px #00000033;
                transform: translateX(190px);
            }

            .page.doubleSidebar > .panels > .panelPrimaryNavigation::after {
                content: '';
                position: absolute;
                left: 201px;
                top: calc(40vh - calc(var(--vertical-used) / 2));
                width: 7px;
                height: 20vh;
                background: #ddd;
                border-radius: 4px;
            }
        }
    `,

    initPage: function() {
        this.classList.add('scrollWrapper');
        this.internal.container.tabIndex = -1;

        var panels = new Element('div', { 'class' : 'panels scrollContainer' });
        this.appendChild(panels);

        this.primary = new Element('div', { 'class' : 'panelPrimaryNavigation' });
        panels.appendChild(this.primary);

        this.secondary = new Element('div', { 'class' : 'panelSecondaryNavigation' });
        panels.appendChild(this.secondary);

        this.content = new Element('div', { 'class': 'panelContent' });
        panels.appendChild(this.content);


        /* Detect right swipe */

        let touchstartX = 0
        let touchstartY = 0;
            
        this.primary.addEventListener('touchstart', e => {
            touchstartX = e.changedTouches[0].screenX
            touchstartY = e.changedTouches[0].screenY
        })

        this.primary.addEventListener('touchmove', e => {
            touchmoveX = e.changedTouches[0].screenX
            touchmoveY = e.changedTouches[0].screenY

            if (Math.abs(touchmoveY - touchstartY) < 20 && touchmoveX - touchstartX > 50) {
                this.show();
            }

            if (Math.abs(touchmoveY - touchstartY) < 20 && touchstartX - touchmoveX > 50) {
                this.hide();
            }
        })

        this.primary.addEventListener('click', e => this.show(), { capture: true });
        this.secondary.addEventListener('click', e => this.hide());
        this.content.addEventListener('click', e => this.hide());
    },

    show: function() {
        if (this.dataset.sidebar != 'visible') {
            this.dataset.sidebar = 'visible';

            this.classList.add('animating');
            setTimeout(() => this.classList.remove('animating'), 400);
        }
    },

    hide: function() {
        if (this.dataset.sidebar == 'visible') {
            this.dataset.sidebar = 'hidden';

            this.classList.add('animating');
            setTimeout(() => this.classList.remove('animating'), 400);
        }
    }
});
