Page.Preview = Class.create(Page.Base, {
    name:		'preview',

    defaults: {
        size: 'medium'
    },

    css: `
        .page.preview { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; color: #000; }
        .page.preview .scrollWrapper { position: absolute; height: 100%; right: 0; overflow-x: hidden; }
        .page.preview .panelContent { position: relative; padding: 30px; box-sizing: border-box; }
        .page.preview .panelPreview { position: absolute; left: 0px; top: 30px; bottom: 0; padding-left: 30px; overflow: hidden; box-sizing: border-box; }

        .page.preview[data-size=medium] > .scrollWrapper { left: 35%; left: max(350px, min(900px, 35%)); }
        .page.preview[data-size=medium] > .panelPreview { width: 35%; width: max(350px, min(900px, 35%)); }

        @media (max-width: 700px) { 
            .page.preview[data-size=medium] > .scrollWrapper { left: 0; }
            .page.preview[data-size=medium] > .panelPreview { display: none; }
        }

        .page.preview[data-size=small] > .scrollWrapper { left: 50%; left: max(300px, min(500px, 50%)); }
        .page.preview[data-size=small] > .panelPreview { width: 50%; width: max(300px, min(500px, 50%)); }

        @media (max-width: 600px) { 
            .page.preview[data-size=small] > .scrollWrapper { left: 0; }
            .page.preview[data-size=small] > .panelPreview { display: none; }
        }
    `,

    initPage: function() {
        this.internal.container.tabIndex = -1;

        if (this.options.size) {
            this.dataset.size = this.options.size;
        }

        var wrapper = new Element('div', { 'class': 'scrollWrapper' });
        this.internal.container.appendChild(wrapper);

        var panels = new Element('div', { 'class' : 'panels scrollContainer' });
        wrapper.appendChild(panels);

        this.content = new Element('div', { 'class': 'panelContent' });
        panels.appendChild(this.content);

        this.preview = new Element('div', { 'class': 'panelPreview' });
        this.internal.container.appendChild(this.preview);
    }
});