(function() {
	let throttledEvent = throttle(() => {
		document.fire("dom:resize");
	}, 100);

	let debouncedEvent = debounce(() => {
		document.body.classList.remove('resizing');
	}, 200);

	addEventListener('resize', () => {
		document.body.classList.add('resizing');
		debouncedEvent();
		throttledEvent();
	});

	addEventListener('orientationchange', () => {
		throttledEvent();
	});
})();