CSS.insert(`
    body .widget.field.phone div.buttons { 
        flex: 0;
        margin-left: 6px; 
        white-space: nowrap;
        display: flex;
    }

    body .widget.field.phone div.buttons .widget.button {
        margin: 0;
    }
`);


Widgets.Field.Phone = Class.create();
Widgets.Field.Phone.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className:      'phone',
            hint:           'tel',
            allow:          '1234567890-.+() ',
            onlyMobile:     false,
            showActions:    false,

            onValidate:     (value) => this._validate(value),

            onValid:        () => {
                if (this.whatsappButton) {
                    this.whatsappButton.enabled = this.field.value ? true : false;
                }

                if (this.callButton) {
                    this.callButton.enabled = this.field.value ? true : false;
                }
            },

            onInvalid:      () => {
                if (this.whatsappButton) {
                    this.whatsappButton.enabled = false;
                }

                if (this.callButton) {
                    this.callButton.enabled = false;
                }
            }
        }, options || {});

        if (this._validate(Formatter.telephone(this.options.value))) {
            this.options.value = Formatter.telephone(this.options.value);
        }

        this.field = new Widgets.Field (parent, this.options);

        if (this.options.showActions) {
            this.buttons = new Element('div', { className: 'buttons' });
            this.field.container.appendChild(this.buttons);

            this.callButton = new Widgets.Button(this.buttons, {
                icon: 'telephone',
                enabled: false,
                style: 'none',
                onClick: () => Application.openVoicecall(this.value)
            });

            if (this.options.onlyMobile) {
                this.whatsappButton = new Widgets.Button(this.buttons, {
                    icon: 'whatsapp',
                    enabled: false,
                    style: 'none',
                    onClick: () => Application.openWhatsapp(this.value)
                });
            }
        }

        this.field.validate();
    },

    destroy: function() {
        this.field.destroy();
    },

    focus: function() {
        this.field.focus();
    },

    blur: function() {
        this.field.blur();
    },

    validate: function() {
        this.field.validate();
    },

    _validate: function(value) {
        value = value.trim();                

        if (this.options.required && value == '') {
            return false;
        }

        let digits = value.replace(/[^0-9]/g, '');
        let onlyMobile = this.options.onlyMobile;

        function isValidDutch(value) {
            if (value.length != 10) {
                return false;
            }

            if (onlyMobile && value.substring(0,2) != '06') {
                return false;
            }

            return true;
        }

        /* Empty */

        if (value == '') {
            return true;
        }


        /* Internation + prefix */

        if (value.substring(0,1) == '+') {
            
            /* Dutch number */
            if (digits.substring(0,2) == '31') {
                return isValidDutch('0' + digits.substring(2));
            }

            return digits.length > 4;
        }

        /* International 00 prefix */

        if (digits.substring(0,2) == '00') {

            /* Dutch number */
            if (digits.substring(2,4) == '31') {
                return isValidDutch('0' + digits.substring(4));
            }

            return digits.length > 4;
        }

        /* Local number with net prefix */

        if (digits.substring(0,1) == '0') {

            return isValidDutch(digits);
        }

        return false;
    },


    get enabled() { return this.field.enabled; },
    set enabled(value) {
        this.field.enabled = value;
    },

    get valid() { return this.field.valid; },

    get value() { return this.field.valid ? Formatter.telephone(this.field.value) : this.field.value },
    set value(value) {
        if (this._validate(Formatter.telephone(value))) {
            value = Formatter.telephone(value);
        }

        this.field.value = value;
    }
};
