
CSS.insert(`
    .widget.search { position: relative; display: flex; line-height: normal; width: 168px; }
    .widget.search input { width: 100%; margin: 0; padding: 5px 5px 4px 22px; border-radius: 4px; border: 1px solid #cecece; background: linear-gradient(to bottom, rgb(245,245,245) 0px, rgb(255,255,255) 5px); font-size: 13px; -webkit-appearance: none; }
    .widget.search:after { position: absolute; top: 2px; content: 'z'; display: block; width: 24px; height: 24px; text-indent: 3px; text-align: center; font-family: SalonWidgets; font-size: 12px; line-height: 24px; font-weight: normal; color: #666; }
    .widget.search.disabled input { opacity: 0.5; }
    .widget.search.disabled:after { opacity: 0.5; }

    .widget.search button {
        position: absolute;
        height: 26px;
        width: 26px;
        top: 0;
        right: 0;

        color: #888;
        background: none;
        border: none;

        font-family: SalonWidgets; 
        font-size: 12px;

        cursor: pointer;

        opacity: 0;
        transition: opacity 0.2s;
    }

    .widget.search input:not(:invalid) + button { opacity: 1; }
    .widget.search.disabled button { display: none; }
`);

Widgets.Search = Class.create();
Widgets.Search.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className:	    null,
            placeholder:    null,
            frequency:	    0.4,
            minChars:	    1,
            enabled:	    true,
            visible:        true,
            manual:		    false,
            clearButton:    false,
        }, options || {});

        this.observer = null;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget search' });
        parent.appendChild(this.container);

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        this.field = new Element('input', { type: 'text' });
        this.field.name = 'search';
        this.field.setAttribute('inputmode','search');
        this.field.setAttribute('autocomplete','off');
        this.field.setAttribute('spellcheck','false');
        this.field.setAttribute('required','');
        this.field.observe('keypress', this.onKeyPress.bind(this));
        this.field.observe('keydown', this.onKeyDown.bind(this));
        this.field.observe('focus', this.onFocus.bind(this));
        this.field.observe('blur', this.onBlur.bind(this));
        this.field.observe('paste', this.onPaste.bind(this));

        if (this.options.placeholder) {
            this.field.placeholder = this.options.placeholder;
        }

        this.container.appendChild(this.field);

        if (this.options.clearButton) {
            this.button = new Element('button');
            this.button.innerHTML = '\u00D7';
            this.button.observe('click', this.clear.bind(this));
            this.container.appendChild(this.button);
        }

        this.options.enabled ? this.enable() : this.disable();
        this.options.visible ? this.show() : this.hide();
    },

    clear: function() {
        this.field.value = '';

        if (this.options.onQuery) {
            this.options.onQuery(this.field.value);
        }
    },

    focus: function() {
        this.field.focus();
    },

    onFocus: function() {
        /*
        this.container.setStyle({
            zIndex:	10000
        });
        */
    },

    blur: function() {
        this.field.blur();
    },

    onPaste: function(event) {
        setTimeout(() => { 
            this.onObserverEvent();
        }, 0);
    },

    onBlur: function() {
        /*
        this.container.setStyle({
            zIndex:	0
        });
        */
    },

    onKeyPress: function(event) {
        if (this.options.manual) {
            if (event.keyCode == Event.KEY_RETURN) {
                if (this.options.onQuery) this.options.onQuery(this.field.value);
            }

            return;
        }

        if (this.observer)
            clearTimeout(this.observer);

          this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
    },

    onKeyDown: function(event) {
        if (event.keyCode == Event.KEY_BACKSPACE ||
            event.keyCode == Event.KEY_DELETE)
        {
            if (this.observer)
                clearTimeout(this.observer);

              this.observer = setTimeout(this.onObserverEvent.bind(this), this.options.frequency * 1000);
        }
    },

    onObserverEvent: function() {
        var value = null;

        if (this.field.value.length >= this.options.minChars) {
            value = this.field.value;
        }

        if (this.field.value == '') {
            value = this.field.value;
        }

        if (value != this.last) {
            this.value = value;
            if (this.options.onQuery) this.options.onQuery(value);
        }
    },

    get value() { return this.field.value; },
    set value(value) {
        this.field.value = value;
    },

    show: function() {
        this.container.show();
        this._visible = true;
    },

    hide: function() {
        this.container.hide();
        this._visible = false;
    },
    
    get visible() { return this._visible; },
    set visible(value) { value ? this.show() : this.hide(); },

    enable: function() {
        this.container.classList.remove('disabled');
        this.field.disabled = false;
    },

    disable: function() {
        this.container.classList.add('disabled');
        this.field.disabled = true;
    },

    get enabled() { return !this.field.disabled; },
    set enabled(value) { value ? this.enable() : this.disable(); },
    get disabled() { return this.field.disabled; },
    set disabled(value) { !value ? this.enable() : this.disable(); }
};
