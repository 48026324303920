CSS.insert(`
	.page { outline: none }
	.page.hidden { transform: translate(-4000px,0); opacity: 0; pointer-events: none; } 

	body[data-viewport=expand] .page.hidden {
		transform: none;
		height: 0px !important;
		min-height: 0px !important;
		overflow: hidden;
	}
`);

let Page = {
    _initialized: {},
};

Page.Base = Class.create({
    isPage:		true,

    used: 0,

    initialize: function(parent, options) {
        this.internal = {
            defaults:	{
                visible:	true
            }
        };

        this.options = {};
        this.options = Object.assign(this.options, this.internal.defaults);
        this.options = Object.assign(this.options, this.defaults || {});
        this.options = Object.assign(this.options, options || {});

        if (typeof Page._initialized[this.name] == 'undefined') this.bootstrap();

        this.internal.container = new Element('div', { 'class': 'page ' + this.name });
        this.internal.container.tabIndex = -1;
        parent.appendChild(this.internal.container);

        if (this.options.className) this.internal.container.classList.add(...this.options.className.split(' '));


        /* Set the used space */

        if (this.used) {

            /* Get the parent's used space */

            let u = 0;
            let p = parent;

            while (p) {
                if (p.style) {
                    let v = p.style.getPropertyValue('--vertical-used');

                    if (v) {
                        u = parseInt(v, 10);	
                    }
                }

                p = p.parentNode;
            }

            this.internal.container.style.setProperty('--vertical-used', `${this.used + u}px`);
        }

        /* Define standard properties */

        this.defineProperty("classList", {
            get:		function() { return this.internal.container.classList; },
        });

        this.defineProperty("dataset", {
            get:		function() { return this.internal.container.dataset; },
        });

        this.defineProperty("visible", {
            get:		function() { return this.internal.visible; },
            set:		function(value) { value ? this.show() : this.hide(); }
        });

        this.internal.timestamp = new Date().getTime();

        this.initPage.apply(this, [...arguments].slice(2));

        if (!this.options.visible) this.hide();
    },

    bootstrap: function() {
        if (this.css) {
            if (typeof this.css == 'string')
                CSS.insert(this.css);
            else
                CSS.load(this.css);
        }

        Page._initialized[this.name] = true;
    },

    initPage: function(options) {
    },

    defineProperty: function(prop, descriptor) {
        Object.defineProperty(this, prop, descriptor);
    },

    addEventListener: function(evt, callback, options) {
        this.internal.container.addEventListener(evt, callback, options || {});
    },

    setStyle: function(s) {
        this.internal.container.setStyle(s);
    },

    appendChild: function(element) {
        this.internal.container.insert(element);
    },

    insert: function(element) {
        this.internal.container.insert(element);
    },

    show: function() {
        this.internal.visible = true;
        this.classList.remove('hidden');

        window.setTimeout(() => {
            this.internal.container.focus();
        }, 100);
    },

    hide: function() {
        this.internal.visible = false;
        this.classList.add('hidden');
    }
});

