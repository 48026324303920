if (typeof Formatter.online === 'undefined') {
    Formatter.online = {};
}


Formatter.online.transactionColor = function(d) {
	if (d == 'created')   		    return 'gray';
	if (d == 'canceled')   			return 'gray';
	if (d == 'failed')              return 'orange';
	if (d == 'expired')             return 'red';
	if (d == 'paid')                return 'green';

    return 'transparent';
}

Formatter.online.transactionStatus = function(d) {
	if (d == 'created')   			return 'Gestart';
	if (d == 'canceled')   			return 'Gestopt';
	if (d == 'failed')              return 'Afgekeurd';
	if (d == 'expired')             return 'Verlopen';
	if (d == 'paid')                return 'Geslaagd';

	return '';
}

Formatter.online.transactionMethod = function(d) {
	if (d == 'creditcard')   		return 'Creditcard';
    if (d == 'ideal')   			return 'iDEAL';
    if (d == 'paypal')   			return 'PayPal';
    if (d == 'bancontact')   		return 'Bancontact';

	return '-';
}

Formatter.online.transactionMethodIcon = function(d) {
	if (d == 'creditcard')   		return '../../images/method-creditcard.svg';
    if (d == 'ideal')   			return '../../images/method-ideal.svg';
    if (d == 'paypal')   			return '../../images/method-paypal.svg';
    if (d == 'bancontact')   		return '../../images/method-bancontact.svg';
}

Formatter.online.transactionBrand = function(d) {
	if (d == 'american express')   	return 'American Express';
    if (d == 'amex')   				return 'American Express';
	if (d == 'bcmc')   				return 'Bancontact';
	if (d == 'carta si')   			return 'Carta Si';
	if (d == 'carte bleue')   		return 'Carte Bleue';
	if (d == 'dankort')   			return 'Dankort';
	if (d == 'diners club')   		return 'Diners Club';
	if (d == 'discover')   			return 'Discover';
	if (d == 'jcb')   				return 'JCB';
	if (d == 'laser')   			return 'Laser';
    if (d == 'maestro')   			return 'Maestro';
	if (d == 'mastercard')   		return 'Mastercard';
	if (d == 'unionpay')   			return 'Unionpay';
    if (d == 'visa')   				return 'VISA';

	return '-';
}
