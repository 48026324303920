CSS.insert(`
    layout-responsive-columns { display: grid; width: 100%; }
    layout-responsive-columns > layout-column { display: block; }
`);

Layout.ResponsiveColumns = Class.create();
Layout.ResponsiveColumns.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            gap:		'30px',
            columns: 	[]
        }, options || {});

        this.columns = {};

        this.container = document.createElement('layout-responsive-columns');
        this.container.style.gridTemplateColumns = `repeat(auto-fit, minmax(${this.options.minWidth},1fr))`;

        if (this.options.gap) {
            this.container.style.gridGap = this.options.gap;
        }

        parent.appendChild(this.container);

        for (let column of this.options.columns) {
            let element = document.createElement('layout-column');
            element.dataset.name = column;
            this.container.appendChild(element);

            this.columns[column] = element;
        }
    }
};