Formatter.terminalIcon = function(m) {
	let icon = '../../images/terminals-default.svg';

	switch (m) {
		case 'Verifone VX820': icon = '../../images/terminals-vx820.svg'; break;
		case 'Verifone P400': icon = '../../images/terminals-p400.svg'; break;
		case 'Verifone V400M': icon = '../../images/terminals-v400m.svg'; break;
		case 'PAX A35': icon = '../../images/terminals-a35.svg'; break;
		case 'PAX A77': icon = '../../images/terminals-a77.svg'; break;
		case 'PAX A920': icon = '../../images/terminals-a920.svg'; break;
	}

	return icon;
}
