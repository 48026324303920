CSS.insert(`
    .layout.panels { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; color: #000; }
    .layout.panels > div > div.panel { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
    .layout.panels > div > div.panel.padding { padding: 30px; }
    .layout.panels > div > div.panel.overflow { overflow-y: scroll; }
    body[data-scroll=custom] .layout.panels > div > div.panel.overflow { border-right: 8px solid rgba(0,0,0,0); }


    /* Expanded viewport */

    body[data-viewport=expand] .layout.panels {
        position: static;
        min-height: 100vh;
    }

    body[data-viewport=expand] .layout.panels > div.panel {
        position: relative;
        top: auto; left: auto; right: auto; bottom: auto;
        min-height: 100vh;
    }

`);

Layout.Panels = Class.create();
Layout.Panels.prototype = {
    initialize: function(parent, data) {
        this.data = data;
        this.items = {};
    
        this.container = new Element('div', { 'class': 'layout panels' });
        parent.appendChild(this.container);
    
        var wrapper = new Element('div');
        this.container.appendChild(wrapper);
            
        for (var i = 0; i < this.data.length; i++) {
            var panel = new Element('div', { 'class': 'panel' });
            wrapper.appendChild(panel);
            
            if (typeof this.data[i].padding != 'undefined' && this.data[i].padding) {
                panel.classList.add('padding');
            }
            
            if (typeof this.data[i].overflow != 'undefined' && this.data[i].overflow) {
                panel.classList.add('overflow');
            }
            
            this.items[this.data[i].name] = panel;
            this.data[i].element = panel;
        }
        
        this.width = this.container.getWidth();
        this.current = null;
    
        this.go(0, false);
    },
    
    back: function(cb) {
        if (this.current > 0) {
            this.go(this.current - 1, true, cb);
        }	
    },
    
    forward: function(cb) {
        if (this.current < this.data.length - 1) {
            this.go(this.current + 1, true, cb);
        }	
    },
    
    goToId: function(id, cb) {
        var n = null;
        
        for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].name == id) {
                n = i; 
            }
        }
        
        if (n != null) {
            this.go(n, true, cb);
        }
    },
    
    go: function(n, animate, cb) {
        if (animate) {
            this.width = Math.round(this.container.getWidth() * 0.66);
        
            if (n > this.current) {
                this.slide(this.data[n].element, 'right', 'center');
                this.slide(this.data[this.current].element, 'center', 'left', cb);
            }
            
            if (n < this.current) {
                this.slide(this.data[n].element, 'left', 'center');
                this.slide(this.data[this.current].element, 'center', 'right', cb);
            }
            
            this.current = n;
        }
        
        else {
            for (var i = 0; i < this.data.length; i++) {
                if (i == n) {
                    this.data[i].element.show();
                } else {
                    this.data[i].element.hide();
                }
            }
            
            if (cb) {
                cb();
            }
            
            this.current = n;
        }
    },
    
    slide: function(el, from, to, cb) {
        var duration = this.width / 4500;
        var begin = (from == 'left' ? 0 - this.width : (from == 'right' ? this.width : 0));
        var end = (to == 'left' ? 0 - this.width : (to == 'right' ? this.width : 0));
        
        if (to == 'center') {
            el.style.opacity = 0;
            el.style.transform = 'translateX(' + begin + 'px)';
            el.show();
        
            el.offsetHeight;
        
            window.setTimeout(function() {
                el.style.transition = 'transform ' + duration + 's ease-in, opacity ' + duration + 's ease-in';
                
                window.setTimeout(function() {
                    el.style.transform = 'translateX(0px)';
                    el.style.opacity = 1;
                }.bind(this), 0);

                window.setTimeout(function() {
                    window.setTimeout(function() {
                        el.style.transition = '';
                        el.style.transform = '';
                    }.bind(this), 0);

                    if (cb) {
                        cb();
                    }
                }.bind(this), duration * 1000);
            }.bind(this), 0);
        }
        
        else {
            el.style.transform = 'translateX(0px)';
            el.style.opacity = 1;

            el.offsetHeight;

            window.setTimeout(function() {
                el.style.transition = 'transform ' + duration + 's ease-in, opacity ' + duration + 's ease-in';

                window.setTimeout(function() {
                    el.style.transform = 'translateX(' + end + 'px)';
                    el.style.opacity = 0;
                }.bind(this), 0);
                
                window.setTimeout(function() {
                    el.hide();
                    
                    window.setTimeout(function() {
                        el.style.transition = '';
                        el.style.transform = '';
                        el.style.opacity = 1;
                    }.bind(this), 0);

                    if (cb) {
                        cb();
                    }
                }.bind(this), duration * 1000);
            }.bind(this), 0);
        }
    },
    
    hide: function() {
        this.container.hide();	
    },
    
    show: function() {
        this.container.show();	
    }
};