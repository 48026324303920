CSS.insert(`
    layout-grid { display: grid; width: 100%; }
    layout-grid > layout-area { display: block; }
`);

Layout.Grid = Class.create();
Layout.Grid.prototype = {

    initialize: function(parent, options) {
        this.options = Object.assign({
            gap:		'30px',
            template:   {
                rows:   	[],
                columns: 	[],
                areas: 		[]
            }
        }, options || {});

        this.areas = {};

        this.container = document.createElement('layout-grid');

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        if (this.options.gap) {
            this.container.style.gridGap = this.options.gap;
        }

        if (this.options.template.rows) {
            this.container.style.gridTemplateRows = 
                typeof this.options.template.rows === 'string' ?
                    this.options.template.rows : 
                    this.options.template.rows.join(' ');
        }

        if (this.options.template.columns) {
            this.container.style.gridTemplateColumns = 
                typeof this.options.template.columns === 'string' ?
                    this.options.template.columns :
                    this.options.template.columns.join(' ');
        }

        parent.appendChild(this.container);

        this.defineArea(this.options.template.areas);
    },

    defineArea(name) {
        if (typeof name == 'string') {
            if (name.includes(' ')) {
                return this.defineArea(name.split(/\s+/));
            }

            if (typeof this.areas[name] !== 'undefined') return;
            if (name === '.') return;

            let element = document.createElement('layout-area');
            element.dataset.name = name;
            this.container.appendChild(element);

            this.areas[name] = element;
        }

        else {
            for (let area of name) {
                this.defineArea(area);
            }
        }
    }
};