CSS.insert(`
    .layout.generic { position: absolute; top: 0; left: 0; right: 0; bottom: 0; color: #000; }
    .layout.generic > div.content { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
    .layout.generic > div.content.padding { padding: 30px; }
    .layout.generic > div.content.overflow { overflow-y: scroll; }
    body[data-scroll=custom] .layout.generic > div.content.overflow { border-right: 8px solid rgba(0,0,0,0); }
`);

Layout.Generic = Class.create();
Layout.Generic.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            overflow: true,
            padding: true,
            visible: true,
            className: null,
        }, options || {});
        
        if (typeof parent.container != 'undefined') parent = parent.container;

        this.element = new Element('div', { 'class': 'layout generic' });
        parent.appendChild(this.element);

        if (this.options.className) {
            this.element.classList.add(...this.options.className.split(' '));
        }

        this.content = new Element('div', { 'class': 'content' });
        this.element.appendChild(this.content);

        if (this.options.padding) this.content.classList.add('padding');
        if (this.options.overflow) this.content.classList.add('overflow');

        if (!this.options.visible) {
            this.hide();
        }
    },
    
    hide: function() {
        this.element.hide();	
    },
    
    show: function() {
        this.element.show();	
    }
};