CSS.insert(`
	.widget { -webkit-user-select: none; margin: 0 0 10px 0; touch-action: manipulation; }
`);

Widgets = (function() {
	var Widgets = {
		_bootstrapped: false,
		_initialized: {},
		_dependancies: {},
		_register: { sheets: [], listening: false },

		set inputMode(value) { document.body.dataset.inputMode = value; },
		get inputMode() { return document.body.dataset.inputMode; }
	};

	Widgets.Base = Class.create({
		isWidget:	true,
		skeleton:	false,

		initialize: function(parent, options) {
			this.internal = {
				defaults:	{
					enabled:	true,
					visible:	true
				}
			};

			this.options = {};
			this.options = Object.assign(this.options, this.internal.defaults);
			this.options = Object.assign(this.options, deepClone(this.defaults) || {});
			this.options = Object.assign(this.options, options || {});

			if (!Widgets._bootstrapped) Widgets.bootstrap();
			if (typeof Widgets._initialized[this.name] == 'undefined') this.bootstrap();

			if (typeof parent.container == 'undefined' && typeof parent.isWidget != 'undefined') parent = parent.internal.container;
			if (typeof parent.container != 'undefined') parent = parent.container;

			if (!this.skeleton) {
				this.internal.container = new Element('div', { 'class': 'widget ' + this.name });
				parent.appendChild(this.internal.container);

				if (this.options.className) this.internal.container.classList.add(...this.options.className.split(' '));

				if (this.options.css) {
					Object.entries(this.options.css).forEach(i => { 
						if (i[1].endsWith('!important')) {
							let property = i[0].replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
							let value = i[1].replace(/!important/g, '');
							this.internal.container.style.setProperty(property, value, 'important');
						} else {
							this.internal.container.style[i[0]] = i[1];
						}
					});
				}

				this.defineProperty("classList", {
					get:		function() { return this.internal.container.classList; },
	            });

				this.defineProperty("dataset", {
					get:		function() { return this.internal.container.dataset; },
	            });

				this.defineProperty("visible", {
					get:		function() { return this.internal.visible; },
					set:		function(value) { value ? this.show() : this.hide(); }
	            });

				this.defineProperty("enabled", {
					get:		function() { return this.internal.enabled; },
					set:		function(value) { value ? this.enable() : this.disable(); }
	            });

				this.defineProperty("disabled", {
					get:		function() { return !this.internal.enabled; },
					set:		function(value) { !value ? this.enable() : this.disable(); }
	            });
			} else {
				this.internal.container = parent;
			}

			if (this.deps && typeof Widgets._dependancies[this.name] == 'undefined') {
				requirejs(this.deps, function(a) {
					Widgets._dependancies[this.name] = true;

					this.initWidget.apply(this, a);

					if (!this.skeleton) {
						this.options.enabled ? this.enable() : this.disable();
						if (!this.options.visible) this.hide();
					}
				}.bind(this, [...arguments].slice(2)));
			}
			else {
				this.initWidget.apply(this, [...arguments].slice(2));

				if (!this.skeleton) {
					this.options.enabled ? this.enable() : this.disable();
					if (!this.options.visible) this.hide();
				}
			}
        },

        destroy: function() {
			if (this.destroyWidget) {
				this.destroyWidget();
			}

			this.internal.container.remove();
        },

		bootstrap: function(callback) {
			if (this.css) {
				if (typeof this.css == 'string')
					CSS.insert(this.css);
				else
					CSS.load(this.css);
			}

			Widgets._initialized[this.name] = true;
		},

		initWidget: function(options) {
		},

		stateChange: function() {
		},

		defineProperty: function(prop, descriptor) {
			Object.defineProperty(this, prop, descriptor);
		},

		addEventListener: function(eventName, handler, phase) {
			this.internal.container.addEventListener(eventName, handler, phase);
		},

		setAttribute: function(attribute, value) {
			this.internal.container.setAttribute(attribute, value);
		},

		getAttribute(attribute) {
			return this.internal.container.getAttribute(attribute);
		},

		setStyle: function(s) {
			this.internal.container.setStyle(s);
		},

		appendChild: function(element) {
			this.internal.container.insert(element);
		},

		insert: function(element) {
			this.internal.container.insert(element);
		},

		insertBefore: function(element, before) {
			this.internal.container.insertBefore(element, before);
		},

		show: function() {
			this.internal.visible = true;
			this.internal.container.show();
			this.stateChange();
		},

		hide: function() {
			this.internal.visible = false;
			this.internal.container.hide();
			this.stateChange();
		},

		toggle: function() {
			this.internal.visible = !this.internal.visible;
			this.internal.container.toggle();
			this.stateChange();
		},

		enable: function() {
			this.internal.enabled = true;
			this.internal.container.classList.remove('disabled');
			this.stateChange();
		},

		disable: function() {
			this.internal.enabled = false;
			this.internal.container.classList.add('disabled');
			this.stateChange();
		}
	});


	Widgets.registerSheet = function(widget, ignore) {

		function check(e) {
			if (!Widgets._register.sheet) return;
			if (!e.target.descendantOf) return close();

			let descendant = false;

			for (let i = 0; i < Widgets._register.ignore.length; i++) {
				if (e.target.descendantOf(Widgets._register.ignore[i])) {
					descendant = true;

					if (e.type == 'scroll') {
						Widgets._register.scrolling = e.timeStamp;
					}
				}
			}

			if (!descendant) {
				close();
			}
		}

		function close() {
			if (Widgets._register.sheet) {
				if (typeof Widgets._register.sheet == 'function') {
					Widgets._register.sheet();
				} else {
					Widgets._register.sheet.close();
				}

				Widgets._register.sheet = null;
				Widgets._register.ignore = [];
			}
		}

		if (!Widgets._register.listening) {
			document.body.observe('click', check);
			document.body.observe('touchstart', check);
			document.observe('application:restore', check);
			document.observe('application:navigation', check);
			document.observe('window:open', check);
			document.observe('sheet:close', check);

			window.addEventListener('scroll', e => { 
				if (e.timeStamp - Widgets._register.scrolling > 400) { 
					check(e); 
				} 
			}, true);

			Widgets._register.listening = true;
		}

		if (Widgets._register.sheet != widget)
			close();

		Widgets._register.sheet = widget;
		Widgets._register.ignore = ignore || [];
		Widgets._register.scrolling = performance.now();
	}

	return Widgets;
})();


Widgets.bootstrap = () => {
	Widgets._bootstrapped = true;
	
	document.addEventListener('mousedown', e => {
		Widgets.inputMode = 'mouse';
	});
	
	document.addEventListener('touchstart', e => {
		Widgets.inputMode = 'mouse';
	});
	
	document.addEventListener('keydown', e => {
		if (e.code == 'Tab' || e.code == 'Enter') {
			Widgets.inputMode = 'keyboard';
		}
	});

	Widgets.inputMode = 'mouse';
}