Page.Receipt = Class.create(Page.Base, {
    name:		'receipt',
    used:		51,

    css: `
        .page.receipt { position: absolute; top: 49px; bottom: 0; left: 0; right: 0; overflow: hidden; overflow-y: scroll; }
        body[data-scroll=custom] .page.receipt { border-right: 8px solid rgba(0,0,0,0); }
        
        .page.receipt { 
            background: #fff; 
            background: linear-gradient(to right, #fff 81px, #ddd 81px, #f6f6f6 82px, #fcfcfc 86px, #fcfcfc 537px, #f6f6f6 541px, #ddd 542px, #fff 542px, #fff 543px); 
            color: #000; 
        }
        
        .page.receipt > .header { position: fixed; top: 50px; z-index: 99; width: 100%; height: 51px; padding: 10px 30px 0px 30px; background: #fff; border-bottom: 1px solid var(--tab-separator); box-sizing: border-box; }
        .page.receipt > .header [data-name="toolbar"] { display: flex; flex-wrap: nowrap; }
        .page.receipt > .panels { display: flex; flex-direction: row; width: 100%; }
        .page.receipt > .panels > * { outline: 1px solid rgba(0,255,255,0); }
        .page.receipt > .panels > .panelNavigation { width: 82px; min-width: 82px; padding: 30px 0 0 0; }
        .page.receipt > .panels > .panelNavigation > * { margin-left: 30px; }
        .page.receipt > .panels > .panelReceipt { width: 460px; min-width: 460px; padding: 30px 0 0 0; }
        
        .page.receipt > .panels > .panelContent { 
            flex: 1; 
            box-sizing: border-box;
            padding: 30px 30px 30px 30px; 
        }


        body[data-viewport=expand] .page.receipt {
            position: static;
            width: 1000px;
            min-height: 100vh;
        }

        body[data-viewport=expand] .page.receipt > .header {
            position: static;
        }



        @media (max-width: 1024px) {

            .page.receipt > .header [data-name="toolbar"] .widget.button .hasCaret {
                --button-display-label: none;
            }

            .page.receipt[data-sidebar=visible] {
                background: linear-gradient(to right, #fff 81px, #ddd 81px, #f6f6f6 82px, #fcfcfc 86px); 
            }

            .page.receipt > .panels > .panelContent {
                position: relative;
                box-sizing: border-box;

                min-height: calc(100vh - var(--vertical-used));
                min-width: 482px;
                padding-bottom: 20px;

                z-index: 1;
                background: #fff;
                box-shadow: 0 0 10px #00000000;
                clip-path: rect(0px 100% 100% -20px);
            }

            .page.receipt.animating > .panels > .panelContent {
                transition: transform 0.4s, box-shadow 0.4s;
                transition-delay: 0s, 0.2s;
            }

            .page.receipt.animating[data-sidebar=visible] > .panels > .panelContent {
                transition-delay: 0s, 0s;
            }

            .page.receipt[data-sidebar=visible] > .panels > .panelContent {
                transform: translateX(calc(max(100vw,564px) - 482px - 542px));
                box-shadow: 0 0 10px #00000033;
            }

            .page.receipt > .panels > .panelContent::after {
                content: '';
                position: absolute;
                left: 11px;
                top: calc(40vh - calc(var(--vertical-used) / 2));
                width: 7px;
                height: 20vh;
                background: #ddd;
                border-radius: 4px;
            }

            [data-scroll="custom"] .page.receipt > .panels > .panelContent {
                padding-right: 42px;
            }
        }
    `,

    initPage: function() {

        /* Header */
        
        let header = new Element('div', { 'class' : 'header' });
        this.appendChild(header);

        let layout = new Layout.Flex(header, {
            orientation:	'horizontal',
            pack:			'justify',
            items: 			[ { name: 'switcher' },  { flexible: true } , { name: 'toolbar' } ]
        });

        this.switcher = layout.items.switcher;
        this.toolbar = layout.items.toolbar;

        /* Panels */

        let panels = new Element('div', { 'class' : 'panels' });
        this.appendChild(panels);

        this.navigation = new Element('div', { 'class': 'panelNavigation' });
        panels.appendChild(this.navigation);

        this.receipt = new Element('div', { 'class': 'panelReceipt' });
        panels.appendChild(this.receipt);

        this.content = new Element('div', { 'class': 'panelContent' });
        panels.appendChild(this.content);


        /* Detect right swipe */

        let touchstartX = 0
        let touchstartY = 0;
        
        this.content.addEventListener('touchstart', e => {
            touchstartX = e.changedTouches[0].screenX
            touchstartY = e.changedTouches[0].screenY
        })

        this.content.addEventListener('touchmove', e => {
            touchmoveX = e.changedTouches[0].screenX
            touchmoveY = e.changedTouches[0].screenY

            if (Math.abs(touchstartY - touchmoveY) < 20 && touchstartX - touchmoveX > 50) {
                this.show();
            }

            if (Math.abs(touchstartY - touchmoveY) < 20 && touchmoveX - touchstartX > 50) {
                this.hide();
            }
        })

        this.content.addEventListener('click', e => this.show(), { capture: true });
        this.receipt.addEventListener('click', e => this.hide());
        this.navigation.addEventListener('click', e => this.hide());	
        
        document.observe('receipt:properties', e => {
            if (new Date().getTime() - this.internal.timestamp < 1000) {
                return;
            }

            requestAnimationFrame(() => {
                this.show();
            });
        });
    },

    toggle: function() {
        if (this.dataset.sidebar == 'visible') {
            this.hide();
        } else {
            this.show();
        }
    },

    show: function() {
        if (this.dataset.sidebar != 'visible') {
            this.dataset.sidebar = 'visible';

            this.classList.add('animating');
            setTimeout(() => this.classList.remove('animating'), 400);
        }
    },

    hide: function() {
        if (this.dataset.sidebar == 'visible') {
            this.dataset.sidebar = 'hidden';

            this.classList.add('animating');
            setTimeout(() => this.classList.remove('animating'), 400);
        }
    }
});