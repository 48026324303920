
CSS.insert(`
    .widget.checkboxGroup {
        display: grid; 
        gap: 16px;
    }
    .widget.checkboxGroup[data-layout="columns"] { 
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    .widget.checkboxGroup .widget.checkbox { margin: 0; }

    .widget.checkboxGroup label { display: block; font-size: 0.8em; color: #666; margin: 0 0 8px; }
`);

Widgets.CheckboxGroup = Class.create();
Widgets.CheckboxGroup.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className: 		null,
            label:			null,
            multiple: 		true,
            columns:		false,
            enabled:		true,
            onChange:		null
        }, options || {});

        this.state = {};
        this.items = {};

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'widget checkboxGroup' });
        parent.appendChild(this.container);

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        if (options.columns) {
            this.container.dataset.layout = options.columns ? 'columns' : 'rows'; 
        }

        if (this.options.label) {
            var label = new Element('label').update(this.options.label);
            this.container.appendChild(label);
        }

        this.options.enabled ? this.enable() : this.disable();
    },

    add: function(options) {
        var checkbox = new Widgets.Checkbox(this, options.selected, {
            className:      typeof options.className !== 'undefined' ? options.className : null,
            description:	options.description,
            onChange: 		function(name, value) {
                                if (!this.options.multiple && !value) {
                                    return false;
                                }

                                value ? this.check(name) : this.uncheck(name);

                                if (this.options.onChange) {
                                    this.options.onChange();
                                }
                            }.bind(this, options.name)
        });

        this.state[options.name] = options.selected;
        this.items[options.name] = checkbox;

        return checkbox;
    },

    uncheck: function(name) {
        this.state[name] = false;
    },

    check: function(name) {
        if (!this.options.multiple) {
            for (n in this.state) {
                if (n != name) {
                    this.state[n] = false;
                    this.items[n].clear();
                }
            }
        }

        this.state[name] = true;
    },

    get value() {
        var result = [];

        for (let name in this.state) {
            if (this.state[name]) {

                if (!this.options.multiple) {
                    return name;
                }

                result.push(name);
            }
        }

        if (!this.options.multiple) {
            return;
        }

        return result;
    },

    enable: function() {
        this.options.enabled = true;
        this.container.classList.remove('disabled');

        for (let key in this.items) {
            this.items[key].enable();
        }
    },

    disable: function() {
        this.options.enabled = false;
        this.container.classList.add('disabled');

        for (let key in this.items) {
            this.items[key].disable();
        }
    },

    get enabled() { return this.options.enabled; },
    set enabled(value) { value ? this.enable() : this.disable(); },
    get disabled() { return !this.options.enabled; },
    set disabled(value) { !value ? this.enable() : this.disable(); }
};
