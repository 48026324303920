Fx = {
	appear: function(e, o) {
		var o = o || {};
		var options = {
			opacity: o.opacity || 1,
			duration: o.duration || 0.2,
			before: o.before || false,
			after: o.after || false,
		}

		e.style.opacity = 0;
		e.style.display = '';
		e.removeAttribute('hidden');

		if (options.before) options.before();


		window.setTimeout(function() {
			e.style.transition = 'opacity ' + options.duration + 's ease-in';

			window.setTimeout(function() {
				e.style.opacity = options.opacity;

				window.setTimeout(function() {
					e.style.transition = '';

					if (options.after) options.after();
				}, 1000 * options.duration);
			}, 0);
		}, 0);
	},

	fade: function(e, o) {
		var o = o || {};
		var options = {
			opacity: o.opacity || 1,
			duration: o.duration || 0.2,
			before: o.before || false,
			after: o.after || false,
		}

		if (options.before) options.before();

		e.style.transition = 'opacity ' + options.duration + 's ease-out';

		window.setTimeout(function() {
			e.style.opacity = 0;

			window.setTimeout(function() {
				e.setAttribute('hidden', '');
				e.style.transition = '';
				e.style.opacity = '';

				if (options.after) options.after();
			}, 1000 * options.duration);
		}, 0);
	},

	scrollToHeight: function(height, container) {
		var options = {};

		if (container) {
			return new Effect.Tween(null,
				container.scrollTop,
				height,
				options,
				function(p) { container.scrollTop = p.round() }
			);
		} else {
			var options = {},
	    	scrollOffsets = document.viewport.getScrollOffsets(),
	    	max = (window.height || document.body.scrollHeight) - document.viewport.getHeight();

			if (options.offset) height += options.offset;

			return new Effect.Tween(null,
				scrollOffsets.top,
				height > max ? max : height,
				options,
				function(p){ scrollTo(scrollOffsets.left, p.round()) }
			);
		}
	},

	scrollIntoView: function (element) {
		var offset = 0;
		var node = element.parentNode;
		while (node) {
			offset += node.offsetTop;
			node.scrollTop = element.offsetTop - offset;
			if (node.scrollTop == element.offsetTop - offset) break;
			node = node.parentNode;
		}
	}
};