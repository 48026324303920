CSS.insert(`
	ui-window.statusWindow .status { position: relative; text-align: center; line-height: 40px; color: #666; padding-top: 24px; text-transform: var(--font-transform); }
	ui-window.statusWindow .status[data-loading=true] { background: url(${_ROOT}images/loader-regular.gif) top center no-repeat; background-size: 24px 24px; }
	@media (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) { ui-window.statusWindow .status[data-loading=true] { background-image: url(${_ROOT}images/loader-regular@2x.gif); } }
	ui-window.statusWindow .status[data-icon]::before { position: absolute; top: -3px; left: 50%; width: 2em; margin-left: -1em; font-size: 2.6em; }
`);

Window.Status = Class.create();
Window.Status.prototype = {
	initialize: function(status) {
		this.window = new Window({
			className: 	'statusWindow',
			width: 		180,
			height: 	50,
			modal:		true
		});

		this.title = new Element('div', { 'className': 'status' }).update(status);
		this.window.container.appendChild(this.title);

		this.icon = 'loading';

		this.window.show();
	},

	update: function(status) {
		this.title.update(status);
	},

	close: function() {
		this.window.close();
	},

	get icon() { return this._icon; },
	set icon(value) { 
		this._icon = value; 

		if (value == 'loading') {
			this.title.dataset.loading = 'true'; 
		} 
			
		else if (value == 'success') {
			this.title.dataset.icon = 'checkmark'; 
			this.title.dataset.iconColor = 'green'; 
			this.title.dataset.loading = null; 
		}

		else {
			this.title.dataset.icon = value; 
			this.title.dataset.iconColor = null; 
			this.title.dataset.loading = null; 
		}
	},
}
