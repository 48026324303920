CSS.insert(`
    .layout.columns { display: table; width: 100%; }
    .layout.columns > div { display: table-row; }
    .layout.columns > div > .column { display: table-cell; vertical-align: top; padding: 0 30px 0 0; }
    .layout.columns > div > .column:last-child { padding: 0; }
`);

Layout.Columns = Class.create();
Layout.Columns.prototype = {
    initialize: function(parent, columns) {
        this.columns = {};
    
        this.container = new Element('div', { 'class': 'layout columns' });
        parent.appendChild(this.container);
    
        var wrapper = new Element('div');
        this.container.appendChild(wrapper);
            
        for (var i = 0; i < columns.length; i++) {
            var column = new Element('div', { 'class': 'column' });
            wrapper.appendChild(column);
            
            if (columns[i].width) {
                column.setStyle({ 'width': columns[i].width });
            }

            if (columns[i].minWidth) {
                column.setStyle({ 'minWidth': columns[i].minWidth });
            }
            
            if (columns[i].maxWidth) {
                column.setStyle({ 'maxWidth': columns[i].maxWidth });
            }
            
            if (columns[i].align) {
                column.setStyle({ 'textAlign': columns[i].align });
            }
            
            this.columns[columns[i].name] = column;
        }
    }
};