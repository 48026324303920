CSS.insert(`
    .layout.toolbar { position: absolute; top: 0; left: 0; right: 0; bottom: 0; color: #000; }
    .layout.toolbar > div.wrapper { position: absolute; top: 0; left: 0; right: 0; height: 60px; z-index: 1; background: linear-gradient(to bottom, rgba(255,255,255,0) 50px, #f6f6f6 51px, rgba(255,255,255,0) 57px); }
    .layout.toolbar > div.wrapper > div.top { width: 100%; height: 41px; border-bottom: 1px solid #ddd; padding: 9px 0 0; }
    .layout.toolbar > div.wrapper > div.top .widget.header { position: relative; top: 6px; }
    .layout.toolbar > div.bottom { position: absolute; top: 50px; left: 0; right: 0; bottom: 0; }
    .layout.toolbar > div.wrapper > div.top.padding { padding-left: 30px; padding-right: 30px; width: calc(100% - 60px); }
    .layout.toolbar > div.bottom.padding { padding: 30px; }
    .layout.toolbar.separator > div.bottom { border-top: 1px solid #eee; }
    .layout.toolbar > div.bottom.overflow { overflow-y: scroll; }
    body[data-scroll=custom] .layout.toolbar > div.bottom.overflow { border-right: 8px solid rgba(0,0,0,0); }


    /* Expanded viewport */

    body[data-viewport=expand] .layout.toolbar {
        position: static;
    }

    body[data-viewport=expand] .layout.toolbar > div.wrapper {
        position: relative;
        top: auto; left: auto; right: auto; bottom: auto;
    }

    body[data-viewport=expand] .layout.toolbar > div.bottom {
        position: relative;
        top: auto; left: auto; right: auto; bottom: auto;
        margin-top: -10px;
        min-height: calc(100vh - 50px);
    }
`);

Layout.Toolbar = Class.create();
Layout.Toolbar.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            separator:	true,
            overflow: true,
            padding: true,
            top: {},
            bottom: {}
        }, options || {});
        
        this.options.top = Object.assign({
            padding: true
        }, this.options.top);
        
        this.options.bottom = Object.assign({
            padding: true
        }, this.options.bottom);
        
        if (typeof parent.container != 'undefined') parent = parent.container;

        this.element = new Element('div', { 'class': 'layout toolbar' });
        parent.appendChild(this.element);

        var wrapper = new Element('div', { 'class': 'wrapper' });
        this.element.appendChild(wrapper);

        this.top = new Element('div', { 'class': 'top' });
        wrapper.appendChild(this.top);

        this.bottom = new Element('div', { 'class': 'bottom' });
        this.element.appendChild(this.bottom);

        if (this.options.separator) this.element.classList.add('separator');
        if (this.options.padding && this.options.top.padding) this.top.classList.add('padding');
        if (this.options.padding && this.options.bottom.padding) this.bottom.classList.add('padding');
        if (this.options.overflow) this.bottom.classList.add('overflow');

        if (this.options.className) {
            this.element.classList.add(...this.options.className.split(' '));
        }
    },
    
    hide: function() {
        this.element.hide();	
    },
    
    show: function() {
        this.element.show();	
    }
};
