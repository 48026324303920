Page.Sidebar = Class.create(Page.Base, {
    name:		'sidebar',

    css: `
        .page.sidebar { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; color: #000; }
        
        .page.sidebar > .panels { 
            display: flex; 
            flex-direction: row; 
            width: 100%; 
            min-height: 100%; 
            background: 
                linear-gradient(to bottom, #fff 0px, rgba(255,255,255,0) 30px), 
                linear-gradient(to right, #fff 220px, #ddd 221px, #f6f6f6 221px, #fff 226px);  
        }

        .page.sidebar > .panels > .panelNavigation { width: 220px; min-width: 220px; padding: 30px 0 0 0; }
        .page.sidebar > .panels > .panelNavigation > * { margin-left: 30px; }
        .page.sidebar > .panels > .panelNavigation .widget.tabbar.verticalType { margin-bottom: 24px; }
        .page.sidebar > .panels > .panelContent { flex: 1; padding: 30px 30px 30px 31px; position: relative; }
        

        /* Expanded viewport */

        body[data-viewport=expand] .page.sidebar {
            position: static;
            width: 1000px;
            min-height: 100vh;
        }

        body[data-viewport=expand] .page.sidebar > .panels { min-height: 100vh; }

        body[data-viewport=expand] .page.sidebar > .panels > .panelContent {
            width: 780px;
            overflow-x: hidden;
        }


        /* Small viewport */

        @media (max-width: 799px) {

            .page.sidebar > .panels {
                background: #fff;
                background: 
                    linear-gradient(to bottom, #fff 0px, rgba(255,255,255,0) 20px), 
                    linear-gradient(to right, #fff 30px, #ddd 31px, #f6f6f6 31px, #fff 36px)
            }

            .page.sidebar > .panels > .panelNavigation {
                position: relative;
                background: #fff;
                padding-top: 30px;
                z-index: 2;
                margin-left: -190px;

                box-shadow: 0 0 10px #00000000;

                transition: transform 0.4s, box-shadow 0.4s;
                transition-delay: 0s, 0.2s;
            }

            .page.sidebar.animating[data-sidebar=visible] > .panels > .panelNavigation {
                transition-delay: 0s, 0s;
            }

            .page.sidebar[data-sidebar=visible] > .panels > .panelNavigation {
                box-shadow: 0 0 10px #00000033;
                transform: translateX(190px);
            }

            .page.sidebar > .panels > .panelNavigation::after {
                content: '';
                position: absolute;
                left: 201px;
                top: calc(40vh - calc(var(--vertical-used) / 2));
                width: 7px;
                height: 20vh;
                background: #ddd;
                border-radius: 4px;
            }
        }
    `,

    initPage: function() {
        this.classList.add('scrollWrapper');
        this.internal.container.tabIndex = -1;

        var panels = new Element('div', { 'class' : 'panels scrollContainer' });
        this.appendChild(panels);

        this.navigation = new Element('div', { 'class' : 'panelNavigation' });
        panels.appendChild(this.navigation);

        this.content = new Element('div', { 'class': 'panelContent' });
        panels.appendChild(this.content);


        /* Detect right swipe */

        let touchstartX = 0
        let touchstartY = 0;
            
        this.navigation.addEventListener('touchstart', e => {
            touchstartX = e.changedTouches[0].screenX
            touchstartY = e.changedTouches[0].screenY
        })

        this.navigation.addEventListener('touchmove', e => {
            touchmoveX = e.changedTouches[0].screenX
            touchmoveY = e.changedTouches[0].screenY

            if (Math.abs(touchmoveY - touchstartY) < 20 && touchmoveX - touchstartX > 50) {
                this.show();
            }

            if (Math.abs(touchmoveY - touchstartY) < 20 && touchstartX - touchmoveX > 50) {
                this.hide();
            }
        })

        this.navigation.addEventListener('click', e => this.show(), { capture: true });
        this.content.addEventListener('click', e => this.hide());
    },

    show: function() {
        if (this.dataset.sidebar != 'visible') {
            this.dataset.sidebar = 'visible';

            this.classList.add('animating');
            setTimeout(() => this.classList.remove('animating'), 400);
        }
    },

    hide: function() {
        if (this.dataset.sidebar == 'visible') {
            this.dataset.sidebar = 'hidden';

            this.classList.add('animating');
            setTimeout(() => this.classList.remove('animating'), 400);
        }
    }
});
