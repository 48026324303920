if (typeof Formatter.online === 'undefined') {
    Formatter.online = {};
}


Formatter.online.providerType = function(d) {
	if (d == 'mollie')   		    return 'Mollie';
    if (d == 'ccv')   			    return 'CCV';
    if (d == 'multisafepay')   		return 'MultiSafepay';

	return '-';
}
