CSS.insert(`
	ui-window.ConfirmWindow .message { font-weight: var(--font-weight-bold); font-size: 1.1em; line-height: 140%; margin: 0 0 10px; }
	ui-window.ConfirmWindow .explaination { color: #000; font-size: 0.8em; font-style: normal; line-height: 130%; }
	ui-window.ConfirmWindow .explaination p + p { margin: 0.7em 0 0 0; }
	ui-window.ConfirmWindow .explaination ul { margin: 0.7em 0 0 1.2em; }
	ui-window.ConfirmWindow .explaination li { margin-bottom: 0.4em; }
	ui-window.ConfirmWindow .widget.checkbox { margin: 10px 0 0; }
`);

Window.Confirm = Class.create();
Window.Confirm.prototype = {
	initialize: function(options) {
		this.options = Object.assign({
			width:			240,
			message: 		'',
			explaination:	null,
			confirm:		null,
			swap:			false,
			yes:			{},
			no:				{},
			yesLabel:   	'Ja',
			noLabel:    	'Nee',
			onClose: 		null,
			sound:			null
		}, options || {});

		this.handled = false;

		this.options.yes = Object.assign({
			title:		this.options.yesLabel,
			color:		null,
			icon:		null
		}, this.options.yes);

		this.options.no = Object.assign({
			title:		this.options.noLabel,
			color:		null,
			icon:		null
		}, this.options.no);

		this.window = new Window({
			width: 		this.options.width,
			height: 	'auto',
			modal:		true,
			className: 	'ConfirmWindow',
			onClose:	this.destroy.bind(this),
			onShow:	    () => {
				if (this.options.no.focus) {
					this.noButton.focus();
				}
		
				if (this.options.yes.focus) {
					this.yesButton.focus();
				}
			}
		});

		this.message = new Element('div', { 'class': 'message' }).update(this.options.message);
		this.window.contents.appendChild(this.message);

		if (this.options.explaination) {
			var explaination = new Element('div', { 'class': 'explaination' }).update(this.options.explaination);
			this.window.contents.appendChild(explaination);
		}

		if (this.options.confirm) {
			this.confirmCheckbox = new Widgets.Checkbox(this.window, false, {
				description:	this.options.confirm,
				onChanged:		this.onCheck.bind(this)
			})
		}

		if (!this.options.swap) {
			this.yesButton = new Widgets.Button(this.window.navigation, {
				title:		this.options.yes.title,
				color:		this.options.yes.color,
				icon:		this.options.yes.icon,
				onClick:	this.onYes.bind(this),
				enabled:	! this.options.confirm
			});
		}

		this.noButton = new Widgets.Button(this.window.navigation, {
			title:		this.options.no.title,
			color:		this.options.no.color,
			icon:		this.options.no.icon,
			onClick:	this.onNo.bind(this)
		});

		if (this.options.swap) {
			this.yesButton = new Widgets.Button(this.window.navigation, {
				title:		this.options.yes.title,
				color:		this.options.yes.color,
				icon:		this.options.yes.icon,
				onClick:	this.onYes.bind(this),
				enabled:	! this.options.confirm
			});
		}

		if (this.options.sound) {
			Application.playSound(this.options.sound);
		}

		this.window.show();
	},

	destroy: function() {
		if (!this.handled && this.options.onClose) {
			this.handled = true;
			this.options.onClose(false);
		}
	},

	onCheck: function() {
		this.yesButton.enabled = this.confirmCheckbox.value;
	},

	onYes: function() {
		if (this.options.onClose) {
			this.handled = true;
			this.options.onClose(true);
		}

		this.window.close();
	},

	onNo: function() {
		if (this.options.onClose) {
			this.handled = true;
			this.options.onClose(false);
		}

		this.window.close();
	}
}