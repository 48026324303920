Page.Tabs = Class.create(Page.Base, {
    name:		'tabs',
    used:		50,

    css: `
        .page.tabs { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; }
        .page.tabs > .panels > .panelNavigation { position: absolute; top: 0; left: 0; right: 0; height: 40px; padding: 10px 30px 0 30px; overflow:hidden;  }
        .page.tabs > .panels > .panelContent { position: absolute; top: 52px; left: 0; right: 0; bottom: 0; /* padding: 0 30px; */ overflow: hidden; }
        

        /* Expanded viewport */

        body[data-viewport=expand] .page.tabs {
            position: static;
            width: 1000px;
            min-height: 100vh;
        }

        body[data-viewport=expand] .page.tabs > .panels { min-height: 100vh; }

        body[data-viewport=expand] .page.tabs > .panels > .panelNavigation {
            position: relative;
            top: auto; left: auto; right: auto; bottom: auto;
        }
        body[data-viewport=expand] .page.tabs > .panels > .panelContent {
            position: relative;
            top: auto; left: auto; right: auto; bottom: auto;
            overflow-y: visible;
            overflow-x: hidden;

        }
    `,

    initPage: function() {
        var panels = new Element('div', { 'class' : 'panels' });
        this.appendChild(panels);

        this.navigation = new Element('div', { 'class' : 'panelNavigation' });
        panels.appendChild(this.navigation);

        this.content = new Element('div', { 'class' : 'panelContent' });
        panels.appendChild(this.content);
    }
});