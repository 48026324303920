CSS.insert(`
    body .widget.field.email div.buttons { 
        flex: 0;
        margin-left: 6px; 
        white-space: nowrap;
        display: flex;
    }

    body .widget.field.email div.buttons .widget.button {
        margin: 0;
    }
`);


Widgets.Field.Email = Class.create();
Widgets.Field.Email.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            className:      'email',
            hint:           'email',
            showActions:    false,
            onValidate:     (value) => this._validate(value),

            onValid:        () => {
                if (this.emailButton) {
                    this.emailButton.enabled = this.field.value ? true : false;
                }
            },

            onInvalid:      () => {
                if (this.emailButton) {
                    this.emailButton.enabled = false;
                }
            }
        }, options || {});

        if (this._validate(Formatter.email(this.options.value))) {
            this.options.value = Formatter.email(this.options.value);
        }

        this.field = new Widgets.Field (parent, this.options);

        if (this.options.showActions) {
            this.buttons = new Element('div', { className: 'buttons' });
            this.field.container.appendChild(this.buttons);

            this.emailButton = new Widgets.Button(this.buttons, {
                icon: { character: '\u2709'},
                enabled: false,
                style: 'none',
                onClick: () => Application.openMail(this.value)
            });
        }

        this.field.validate();
    },

    destroy: function() {
        this.field.destroy();
    },

    focus: function() {
        this.field.focus();
    },

    blur: function() {
        this.field.blur();
    },

    validate: function() {
        this.field.validate();
    },

    _validate: function(value) {
        value = value.trim();                

        if (this.options.required && value == '') {
            return false;
        }

        let invalid = 
            (value != '' && !/^[^\s@]+@[^\s@]+\.[^\s@;]+$/.test(value)) || 
            /(\.con$|\.coim$|\.ccom$|\.comm$|\.nl\.nl$|\.nll$|\.n$)/i.test(value) ||
            /(gmai\.com|gmaol\.com|gmaill\.com|gamil\.com|gmail\.nl|homail\.|hotmai\.com|hormail\.com|hotmaol\.com|kpn\.mail\.nl|outlock\.com|jahoo\.com)/i.test(value);

        return !invalid;
    },

    get enabled() { return this.field.enabled; },
    set enabled(value) {
        this.field.enabled = value;
    },

    get valid() { return this.field.valid; },

    get value() { return this.field.valid ? Formatter.email(this.field.value) : this.field.value },
    set value(value) {
        if (this._validate(Formatter.email(value))) {
            value = Formatter.email(value);
        }

        this.field.value = value;
    }
};
