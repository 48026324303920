
CSS.insert(`
    .widget.chart { display: flex; position: relative; }
    .widget.chart:empty { }
    .widget.chart > div { position: absolute !important; }


    .widget.chart path.highcharts-legend-nav-active {
        fill: var(--colors-blue);
    }

    .widget.chart circle.highcharts-legend-nav-active:hover {
        fill: rgba(0,0,0,0.05);
    }
`);

Widgets.Chart = Class.create();
Widgets.Chart.prototype = {
    initialize: function(parent, options) {
        this.options = options;
        this.rendered = false;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.element = new Element('div', { 'class': 'widget chart' });
        parent.appendChild(this.element);

        if (this.options.height) {
            this.element.setStyle({ height: this.options.height });
        }

        if (this.options.width) {
            this.element.setStyle({ width: this.options.width });
        }

        if (this.isHidden()) {
            document.observe('widget:render', e => {
                if (!this.rendered && this.element.descendantOf(e.memo)) {
                    this.render();
                }
            })
        } else {
            this.render();
        }
    },

    destroy: function() {
        this.element.remove();
    },

    render: async function() {
        this.rendered = true;

        await requireAsync(['../bundles/widgets/chart']);
        
        let defaults = {
            time: {
                timezone: 'Europe/Amsterdam',
            },

            colors: [
                'var(--chart-blue-color)', 
                'var(--chart-red-color)', 
                'var(--chart-green-color)', 
                'var(--chart-purple-color)', 
                'var(--chart-teal-color)', 
                'var(--chart-orange-color)', 
                'var(--chart-lime-color)', 
                'var(--chart-brown-color)', 
                'var(--chart-yellow-color)'
            ],

            chart: {
                renderTo: Element.identify(this.element),
                reflow: true,
                backgroundColor: null,
                style: {
                    fontFamily: 'system-ui, -apple-system, -apple-system-font, BlinkMacSystemFont, ".SFNSDisplay-Regular", HelveticaNeue, "SegoeUI", Helvetica, Arial, sans-serif'
                }
            }, 

            title: {
                text: null
            },

            credits: { 
                enabled: false 
            },

            legend: {
                align: 'right',
                layout: 'horizontal',
                y: 16,

                itemMarginTop: 0,
                itemMarginBottom: 0,
                itemStyle: {    
                    color: '#333333', 
                    cursor: 'pointer', 
                    fontSize: '12px', 
                    fontWeight: 'normal', 
                    textOverflow: 'ellipsis'
                }
            }, 

            tooltip: {
                borderRadius: 4,
                borderWidth: 2,
                shadow: {
                    color: 'rgba(0,0,0,0.1)',
                    width: 0,
                    offsetX: 0,
                    offsetY: 2
                },
            },

            plotOptions: {
                area: {
					animation: false,
					stacking: 'normal',
                    fillOpacity: 0.9,
					marker: {
						enabled: false,
                        symbol: 'circle',
                        radius: 4,
                        lineWidth: 2,

                        states: {
                            hover: {
                                enabled: true,
                            }
                        }   
					}
                },

                areaspline: {
                    animation: false,
					stacking: 'normal',
                    fillOpacity: 0.9,
					marker: {
						enabled: false,
                        symbol: 'circle',
                        radius: 4,
                        lineWidth: 2,

                        states: {
                            hover: {
                                enabled: true,
                            }
                        }   
					}
                },

				bar: {
					animation: false,
					stacking: 'normal',
					borderRadius: '4px',
					marker: {
						enabled: false
					}
				},

                column: {
                    animation: false,
                    stacking: 'normal',
                    borderRadius: '4px'
                },

                spline: {
                    animation: false,
                    marker: {
						enabled: false,
                        symbol: 'circle',
                        radius: 4,
                        lineWidth: 0,

                        states: {
                            hover: {
                                enabled: true,
                                radius: 4,
                                lineWidth: 2,
                            }
                        }   
					}
                },

                pie: {
                    animation: false,
                    borderColor: 'var(--background)',
                    borderWidth: 2,
                    borderRadius: 4,
                    innerSize: '50%',

                    showInLegend: true,
					allowPointSelect: true,

                    dataLabels: {
                        distance: -25,
                        color: 'white',
                        style: {
                            textOutline: '1px rgba(0,0,0,0.1)'
                        }
                    }
                }
			}
        }


        let options = merge(defaults, this.options);

        if (options.xAxis) {
            function xAxis(axis) {
                if (axis.title && axis.title.margin) {
                    axis.title.offset = axis.title.margin;
                }
            
                return merge({
                    lineWidth: 1,
                    lineColor: '#ccc',
                    tickColor: '#ccc',
                    labels: {
                        y: 20,
                        style: {
                            fontSize: '11px'
                        }
                    }
                }, axis);
            }

            options.xAxis = options.xAxis instanceof Array ? 
                options.xAxis.map(xAxis) : 
                xAxis(options.xAxis);
        }


        if (options.yAxis) {
            function yAxis(axis) {
                if (axis.title && axis.title.margin) {
                    axis.title.offset = axis.title.margin;
                }

                return merge({
                    title: {
                        style: {
                            color: '#888',
                            fontSize: '12px'
                        }
                    },
                    labels: {
                        style: {
                            fontSize: '11px'
                        }
                    }
                }, axis);
            }

            options.yAxis = options.yAxis instanceof Array ? 
                options.yAxis.map(yAxis) : 
                yAxis(options.yAxis);
        }
    
        this.chart = new Highcharts.Chart(options);
    },

    isHidden: function() {
        return this.element.offsetHeight == 0;
    }
}