CSS.insert(`
    .layout.flex { display: flex; }
    .layout.flex[data-orientation="horizontal"] { width: 100%; flex-direction: row; }
    .layout.flex[data-orientation="vertical"] { height: 100%; flex-direction: column; }
    .layout.flex[data-pack="start"] { justify-content: flex-start; }
    .layout.flex[data-pack="end"] { justify-content: flex-end; }
    .layout.flex[data-pack="center"] { justify-content: center; }
    .layout.flex[data-pack="justify"] { justify-content: space-between; }
    .layout.flex[data-align="start"] { align-items: flex-start; }
    .layout.flex[data-align="end"] { align-items: flex-end; }
    .layout.flex[data-align="center"] { align-items: center; }
    .layout.flex[data-align="stretch"] { align-items: stretch; }
    .layout.flex > .box { display: block; box-sizing: border-box; }
    .layout.flex > .box.flexible { flex: auto; }
    .layout.flex > .box > .widget:last-child { margin-right: 0; }
`);

Layout.Flex = Class.create();
Layout.Flex.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            orientation: 	'horizontal',
            align:			'stretch',
            pack:			'start',
            visible:		true,
            className:		null,
            gap:			null,
            items:			[]
        }, options || {});
    
        this.items = {};
    
        if (typeof parent.container != 'undefined') parent = parent.container;
        this.container = new Element('div', { 'class': 'layout flex' });
        this.container.dataset.orientation = this.options.orientation;
        this.container.dataset.pack = this.options.pack;
        this.container.dataset.align = this.options.align;
        parent.appendChild(this.container);

        if (this.options.className) {
            this.container.classList.add(this.options.className);
        }

        if (this.options.gap) {
            this.container.style.gap = this.options.gap;
        }

        for (var i = 0; i < this.options.items.length; i++) {
            var item = new Element('div', { 'class': 'box ' + (this.options.items[i].name ? this.options.items[i].name + 'Box': '') });
            item.dataset.name = this.options.items[i].name || '';
            this.container.appendChild(item);
            
            var attributes = [ 'width', 'minWidth', 'maxWidth', 'height', 'minHeight', 'maxHeight' ];
            var style = {};

            for (var a = 0; a < attributes.length; a++) {
                if (this.options.items[i][attributes[a]]) {
                    style[attributes[a]] = this.options.items[i][attributes[a]];
                }
            }
            
            item.setStyle(style);

            if (this.options.items[i].flexible) {
                item.classList.add('flexible');
            }
            
            if (this.options.items[i].name) {
                this.items[this.options.items[i].name] = item;
            } else {
                this.items['unnamed-' + i] = item;
            }
        }

        if (!this.options.visible) this.hide();
    },
    
    show: function() {
        this.container.show();
        this.options.visible = true;
    },
    
    hide: function() {
        this.container.hide();
        this.options.visible = false;
    },
    
    toggle: function() {
        this.container.toggle();
        this.options.visible = !this.options.visible;
    },

    get visible() { return this.options.visible; },
    set visible(value) { value ? this.show() : this.hide(); },	
};