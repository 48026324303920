Page.Login = Class.create(Page.Base, {
    name:		'login',

    css: `
        .page.login { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; }
        body.hasHeader .page.login { top: 36px; }
        body.hasFooter .page.login { bottom: 49px; }
        .page.login > .panels > .panelContent { position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 0; background: radial-gradient(circle, #f6f6f6, #ccc); }
    `,

    initPage: function() {
        var panels = new Element('div', { 'class' : 'panels' });
        this.appendChild(panels);

        this.content = new Element('div', { 'class' : 'panelContent' });
        panels.appendChild(this.content);
    }
});
