Page.Generic = Class.create(Page.Base, {
    name:		'generic',

    css: `
        .page.generic { position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden; overflow-y: scroll; outline: none; }
        body[data-scroll=custom] .page.generic { border-right: 8px solid rgba(0,0,0,0); }
        .page.generic > .panels { display: flex; flex-direction: row;; width: 100%; }
        .page.generic > .panels > * { outline: 1px solid rgba(0,255,255,0); }
        .page.generic > .panels > .panelContent { flex: 1; padding: 30px 30px 30px 30px; position: relative; }


        /* Expanded viewport */

        body[data-viewport=expand] .page.generic {
            position: static;
            width: 1000px;
            min-height: 100vh;
            overflow: visible;
        }
    `,

    initPage: function() {
        var panels = new Element('div', { 'class' : 'panels' });
        this.appendChild(panels);

        this.content = new Element('div', { 'class': 'panelContent' });
        panels.appendChild(this.content);
    }
});