Page.List = Class.create(Page.Base, {
    name:		'list',
    used:		52,

    css: `
        .page.list { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; }
        .page.list > .panels > .panelToolbar { position: absolute; top: 0; left: 0; right: 0; height: 40px; padding: 12px 0 0 0; overflow:hidden;  }
        .page.list > .panels > .panelContent { position: absolute; top: 52px; left: 0; right: 0; bottom: 0; padding: 30px 30px 0; background: linear-gradient(to bottom, #ddd 0px, #f6f6f6 1px, #fff 7px); color: #000; }


        /* Expanded viewport */

        body[data-viewport=expand] .page.list {
            position: static;
            width: 1000px;
            min-height: 100vh;
        }

        body[data-viewport=expand] .page.list > .panels { min-height: 100vh; }
        body[data-viewport=expand] .page.list > .panels > .panelToolbar {
            position: static;
        }
        body[data-viewport=expand] .page.list > .panels > .panelContent {
            position: static;
        }
    `,

    initPage: function() {
        var panels = new Element('div', { 'class' : 'panels' });
        this.appendChild(panels);

        this.toolbar = new Element('div', { 'class' : 'panelToolbar' });
        panels.appendChild(this.toolbar);

        var wrapper = new Element('div', { 'class' : 'panelContent scrollWrapper' });
        wrapper.tabIndex = -1;
        panels.appendChild(wrapper);

        this.content = new Element('div', { 'class' : 'scrollContent' });
        wrapper.appendChild(this.content);
    }
});