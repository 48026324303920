Formatter.paymentRequestType = function(m) {
	switch(m) {		
        case 'delayed': 	        	return 'Openstaande bon';
        case 'deposit': 	            return 'Aanbetaling';
        case 'reversal': 	            return 'Stornering abonnement';
	}	

	return '-';
}

Formatter.paymentRequestStatus = function(m) {
	switch(m) {		
        case 'created': 	        	return 'Verzonden';
        case 'started': 	        	return 'Verzonden';
        case 'canceled': 	            return 'Geannuleerd';
		case 'expired': 	            return 'Verlopen';
        case 'paid': 	            	return 'Betaald';
	}	

	return '-';
}
