
Widgets.EditableDataTable = Class.create(Widgets.Base, {
	name:		'editableDataTable',

	css: 		[ '../../../core/lib/tabulator/css/salonhub/tabulator.css' ],

	initWidget: function() {
		if (typeof Tabulator != 'undefined') {
			this.build();
			return;
		}

		requirejs(
			[ 'core/lib/tabulator/js/tabulator' ],

			function(Tabulator) {

				Tabulator.prototype.extendModule("format", "formatters", {
					tax: function(cell, formatterParams){
						return Formatter.tax(cell.getValue());
					},
					
					currency: function(cell, formatterParams){
						return Formatter.currency(cell.getValue(), 2);
					},
					
					checkbox: function(cell, formatterParams){
						var input = document.createElement("input");			
						input.setAttribute("type", "checkbox");
						input.checked = !!cell.getValue();

						input.addEventListener('change', function(e) {
							if (typeof formatterParams.selectRow !== 'undefined' && formatterParams.selectRow) {
								if (e.target.checked) {
									cell.getRow().select();
								} else {
									cell.getRow().deselect();
								}
							}

							cell.setValue(e.target.checked, false);
						});

						input.addEventListener('click', function(e) {
							e.stopPropagation();
						});

						cell.getElement().addEventListener('click', function(e) {
							e.stopPropagation();

							var value = !cell.getValue();

							if (typeof formatterParams.selectRow !== 'undefined' && formatterParams.selectRow) {
								if (value) {
									cell.getRow().select();
								} else {
									cell.getRow().deselect();
								}
							}

							cell.setValue(value, false);
						});

						return input;						
					},
				});

				Tabulator.prototype.extendModule("edit", "editors", {
					currency: function(cell, onRendered, success, cancel, editorParams){
						var cellValue = Formatter.number(cell.getValue(), 2, false),
							input = document.createElement("input");
				
						input.setAttribute("type", "text");
				
						input.style.padding = "1px 5px";
						input.style.width = "100%";
						input.style.boxSizing = "border-box";
						input.style.textAlign = 'right';
						input.style.fontSize = '100%'
				
						input.value = typeof cellValue !== "undefined" ? cellValue : "";
				
						onRendered(function () {
							input.focus();
							input.select();
							input.style.height = "100%";
						});
				
						function onChange(e) {
							if ((cellValue === null || typeof cellValue === "undefined") && input.value !== "" || input.value != cellValue) {
								success(parseFloat(String(input.value).replace(',','.')) || 0);
							} else {
								cancel();
							}
						}
				
						//submit new value on blur or change
						input.addEventListener("change", onChange);
						input.addEventListener("blur", onChange);
				
						//submit new value on enter
						input.addEventListener("keydown", function (e) {
							switch (e.keyCode) {
								case 13:
									success(parseFloat(String(input.value).replace(',','.')) || 0);
									break;
				
								case 27:
									cancel();
									break;
							}
						});
				
						input.addEventListener("keypress", function (e) {
							if (e.charCode != 0 && ('1234567890,.').indexOf(String.fromCharCode(e.charCode)) < 0) {
								e.preventDefault();
								return;
							}
						});

						return input;						
					}
				});

				window.Tabulator = Tabulator;
				this.build();
			}.bind(this)
		)
	},

	destroyWidget: function() {
		if (this.table) {
			this.table.destroy();
		}
	},

	build: function() {
		if (this.options.columns) {
			this.options.columns.forEach(function(column) {
				column.headerContext = this.headerContextMenu.bind(this)
			}.bind(this));
		}

		this.table = new Tabulator(this.internal.container, this.options);
	},

	headerContextMenu: function(e, column) {
		e.preventDefault();

		var columns = column.getTable().getColumns();

		var items = [];


		columns.forEach(function(column) {
			var enabled = true;
			var toggle = typeof column.getDefinition().toggle == 'undefined' ? false : !! column.getDefinition().toggle;

			if (toggle && enabled) { 
				items.push({
					title:		column.getDefinition().title,
					checked:	column.getVisibility(),
					onSelect:	function() {
									column.toggle()
									column.getTable().redraw(true);
					}
				})
			}
		}.bind(this));

		if (items.length) {
			new Widgets.ContextMenu({
				x:			e.clientX,
				y:			e.clientY,
				items:		items
			});
		}
	}
});

