Page.Calendar = Class.create(Page.Base, {
    name:		'calendar',
    used:		104,

    css: `
        .page.calendar { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff; }
        .page.calendar > .panels > .panelToolbar { position: absolute; top: 0; left: 0; right: 0; height: 40px; padding: 12px 16px 0 16px; }
        .page.calendar > .panels > .panelHeader  { position: absolute; top: 52px; left: 0; right: 0; height: 52px; padding: 0; overflow: hidden; background: linear-gradient(to bottom, #eee 0px, #fff 4px); color: #000; }
        .page.calendar > .panels > .panelContent { position: absolute; top: 104px; left: 0; right: 0; bottom: 0; padding: 0; background: linear-gradient(to bottom, #eee 0px, #f6f6f6 10px); color: #000; }


        /* Expanded viewport */

        body[data-viewport=expand] .page.calendar {
            position: static;
            width: 100vw;
            min-height: 100vh;
        }

        body[data-viewport=expand] .page.calendar > .panels { min-height: 100vh; }

        body[data-viewport=expand] .page.calendar > .panels > .panelToolbar {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
        }
        body[data-viewport=expand] .page.calendar > .panels > .panelHeader {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
        }
        body[data-viewport=expand] .page.calendar > .panels > .panelContent {
            position: relative;
            top: auto;
            left: auto;
            right: auto;

            overflow: hidden !important;
        }
    `,

    initPage: function() {
        var panels = new Element('div', { 'class' : 'panels' });
        this.appendChild(panels);

        this.toolbar = new Element('div', { 'class' : 'panelToolbar' });
        panels.appendChild(this.toolbar);

        this.header = new Element('div', { 'class' : 'panelHeader' });
        panels.appendChild(this.header);

        this.wrapper = new Element('div', { 'class' : 'panelContent scrollWrapper' });
        this.wrapper.tabIndex = -1;
        panels.appendChild(this.wrapper);

        this.content = new Element('div', { 'class' : 'scrollContent' });
        this.wrapper.appendChild(this.content);

        document.observe('calendar:scroll', this.onScroll.bind(this));
    },

    onScroll: function(e) {
        var scroll = e.memo;
        if (scroll.height) {
            var height = scroll.height + this.content.positionedOffset()[1];

            if (scroll.effect) {
                if (height != this.wrapper.scrollTop) new Fx.scrollToHeight(height, this.wrapper);
            } else {
                this.wrapper.scrollTop = height;
            }
        }
    }
});