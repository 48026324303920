CSS.insert(`
    layout-responsive-aside { 
        width: 100%; 
        display: block;
        container-type: inline-size;
    }    
`);

Layout.ResponsiveAside = Class.create();
Layout.ResponsiveAside.prototype = {
    initialize: function(parent, options) {
        this.options = Object.assign({
            gap:		'30px',
            minWidth:   '400px',
            aside:      {}
        }, options || {});

        this.options.aside = Object.assign({
            gap:		null,
            minWidth:	'200px',
            maxWidth:	'200px',
            position:   'bottom'
        }, this.options.aside || {});


        let order = this.options.aside.position == 'top' ? -1 : 0;
        let gap = this.options.gap.split(' ').pop();

        this.container = document.createElement('layout-responsive-aside');
        parent.appendChild(this.container);

        CSS.apply(this.container, `
            :scope > layout-grid { 
                display: grid;
                grid-template-columns: 1fr minmax(${this.options.aside.minWidth}, ${this.options.aside.maxWidth});
                grid-template-rows: 1fr;
                grid-gap: ${this.options.gap};
            }

            :scope > layout-grid > layout-column[data-name="aside"] {
                align-self: start;

                display: grid; 
                grid-gap: ${this.options.aside.gap || this.options.gap};
            }

            @container (max-width: calc(${this.options.minWidth} + ${this.options.aside.minWidth} + ${gap})) {

                :scope > layout-grid { 
                    grid-template-columns: 1fr;
                }

                :scope > layout-grid > layout-column[data-name="aside"] {
                    grid-template-columns: repeat(auto-fit, minmax(${this.options.aside.minWidth}, 1fr));
                    order: ${order};
                }
            }

            @container (max-width: calc(${this.options.aside.minWidth} * 2 + ${gap})) {

                :scope > layout-grid > layout-column[data-name="aside"] {
                    grid-template-columns: 1fr;
                }
            }

        `);


        this.grid = document.createElement('layout-grid');
        this.container.appendChild(this.grid);


        this.columns = {};

        this.columns.content = document.createElement('layout-column');
        this.columns.content.dataset.name = 'content';
        this.grid.appendChild(this.columns.content);

        this.columns.aside = document.createElement('layout-column');
        this.columns.aside.dataset.name = 'aside';
        this.grid.appendChild(this.columns.aside);
    }
};