
Widgets.QRCode = Class.create(Widgets.Base, {
    name:		'qrcode',
    skeleton:	true,

    defaults: {
        value:         '',
        size:          256,
        padding:       {},
    },

    initWidget: async function() {
        let container = document.createElement('canvas');
        container.style.width = this.options.size + 'px';
        container.style.height = this.options.size + 'px';
        container.style.imageRendering = 'pixelated';
        
        if (this.options.padding.top) {
            container.style.marginTop = this.options.padding.top + 'px';
        }

        if (this.options.padding.bottom) {
            container.style.marginBottom = this.options.padding.bottom + 'px';
        }

        if (this.options.padding.left) {
            container.style.marginLeft = this.options.padding.left + 'px';
        }

        if (this.options.padding.right) {
            container.style.marginRight = this.options.padding.right + 'px';
        }

        this.appendChild(container);

        
        (async () => {
            let { correction, mode, generate } = await requireAsync([ '../core/lib/lean-qr' ]);

            let qrcode = generate(this.options.value, {
                correction: correction[this.options.correction],
                mode: mode[this.options.mode]
            });
    
            qrcode.toCanvas(container, {
                padX: 0,
                padY: 0
            });
        })();
    }
});
